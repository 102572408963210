var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"candidate_nav_sidebar"}},[_c('v-app-bar',{staticClass:"navbar",attrs:{"color":"background","app":"","elevation":"0"}},[_c('div',{staticClass:"d-flex nav-cards"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{staticClass:"mt-4",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),(_vm.$route && _vm.$route.meta)?[_c('div',{staticClass:"base-card d-none d-md-flex align-center route-details"},[_c('div',{staticClass:"route-icon d-flex align-center justify-center"},[_c('inline-svg',{attrs:{"src":_vm.$route.meta.icon || _vm.active_route_icon}})],1),_c('p',{staticClass:"base-body-text mb-0 mx-4 route-name ellipse-text"},[_vm._v(" "+_vm._s(_vm.extract_translation_from_key(_vm.$route.meta.name) || _vm.$t("nav-sidebar.not-found"))+" ")]),(_vm.$route.meta.credit_logo)?_c('div',{staticClass:"ms-auto"},[_c('v-img',{attrs:{"src":_vm.$route.meta.credit_logo}})],1):_vm._e()])]:_vm._e(),_c('ChangeLanguage'),_c('div',{staticClass:"notifications-banner"},[_c('v-badge',{attrs:{"bordered":"","color":"danger","offset-x":"11.5","offset-y":"19","content":_vm.get_notifications_count.toString(),"value":_vm.get_notifications_count.toString()},nativeOn:{"click":function($event){return _vm.toggle_notification_overlay.apply(null, arguments)}}},[_c('div',{staticClass:"base-card d-flex align-center justify-center notifications"},[_c('img',{attrs:{"src":_vm.notification_icon,"alt":"Notification"}})])]),(_vm.notification_overlay)?_c('div',{staticClass:"notifications-banner-sheet",class:_vm.get_site_direction === _vm.SiteDirections.RTL
              ? _vm.$vuetify.breakpoint.smAndUp
                ? 'pl-15'
                : 'notifications-banner-sheet-arabic'
              : ''},[_c('div',{staticClass:"notifications-banner-sheet-content"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('p',{staticClass:"mb-0 base-body-text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("shared.notifications"))+" ")]),_c('v-btn',{staticClass:"text-none mr-n2",attrs:{"loading":_vm.notification_action_loading,"small":"","text":"","color":"primary","disabled":_vm.get_notifications_count <= 0},on:{"click":_vm.mark_all_as_read}},[_vm._v(" "+_vm._s(_vm.$t("shared.mark-read"))+" ")])],1),(_vm.get_notifications_count <= 0)?[_c('DataNotFound',{attrs:{"title":_vm.extract_translation_from_key('shared.not-found')}})]:_vm._l((_vm.get_notifications),function(notification,index){return _c('v-banner',{key:index,scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.notification_action_loading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.dismiss_notification(notification)}}},[_c('v-icon',[_vm._v("mdi-circle-outline")])],1),_c('v-btn',{attrs:{"loading":_vm.notification_action_loading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.notification_navigate(notification)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-right" : "mdi-arrow-left")+" ")])],1)]},proxy:true}],null,true)},[_vm._v(_vm._s(notification.message)+" ")])})],2)]):_vm._e()],1),(_vm.user)?_c('v-menu',{attrs:{"nudge-bottom":"80","rounded":"lg","content-class":"elevation-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"base-card user-dropdown-menu d-flex align-center"},'div',attrs,false),on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-avatar',{staticClass:"profile-avatar",attrs:{"size":55}},[(_vm.user.avatar_uri)?_c('img',{attrs:{"src":_vm.user.avatar_uri,"alt":_vm.user.first_name}}):_c('span',[_vm._v(" "+_vm._s(_vm.get_name_initials(_vm.user.first_name + " " + _vm.user.last_name))+" ")])]):_vm._e(),_c('div',{staticClass:"profile-data d-none d-sm-block",class:{
                'mr-4': _vm.get_site_direction === _vm.SiteDirections.RTL,
                'ms-4': _vm.get_site_direction === _vm.SiteDirections.LTR
              }},[_c('p',{staticClass:"base-body-text mb-0 ellipse-text"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.user.first_name))+" "),(_vm.user.last_name)?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.user.last_name))+" ")]):_vm._e()]),(
                  _vm.module === 'candidate' &&
                  !_vm.auth_loading &&
                  _vm.get_latest_work_history
                )?_c('p',{staticClass:"base-body-text mb-0 ellipse-text"},[_vm._v(" "+_vm._s(_vm.get_latest_work_history.job_title)+" ")]):_vm._e()]),_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":"","color":"primary"}},[_c('inline-svg',{attrs:{"src":_vm.dropdown_icon}})],1)],1)]}}],null,false,60494497)},[(!_vm.ifInterview)?_c('v-list',[(_vm.module === 'candidate')?_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","to":"/candidate/profile"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("nav-sidebar.view-profile")))])],1):_vm._e(),_c('v-list-item',{staticClass:"px-6",on:{"click":_vm._logout_user}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("auth.logout-title"))+" ")])],1)],1):_vm._e()],1):_vm._e()],2)]),_c('v-navigation-drawer',{staticClass:"sidebar",staticStyle:{"z-index":"100"},attrs:{"app":"","left":"","right":_vm.get_site_direction === _vm.SiteDirections.RTL},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.COREHCS))?_c('img',{attrs:{"src":require("@/assets/logos/corehcs.svg"),"alt":"CoreHCS"}}):(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.COREHCMS))?_c('v-img',{attrs:{"width":"120px","src":require("@/assets/logos/core42-hcms.svg"),"alt":"Core-HCMS"}}):(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.INCEPTION))?_c('v-img',{attrs:{"width":"180px","src":require("@/assets/logos/hcms_logo.jpeg"),"alt":"Core-HCMS"}}):_c('img',{attrs:{"src":require("@/assets/logos/hcms.svg"),"alt":"HCMS"}}),_c('v-list',{staticClass:"sidebar-links mt-10 px-0",attrs:{"nav":""}},_vm._l((_vm.SidebarContent),function(link,index){return _c('div',{key:index},[(link.module.includes(_vm.module) && link.children.length <= 0)?_c('v-list-item',{class:_vm.get_site_direction === _vm.SiteDirections.LTR
              ? 'sidebar-link px-4'
              : 'sidebar-link-ltr px-4',attrs:{"active-class":"active","color":"primary","link":"","to":link.link}},[_c('v-list-item-icon',{staticClass:"mr-4 ms-2 sidebar-icon"},[_c('inline-svg',{attrs:{"src":_vm.$route.fullPath === link.link.trim()
                  ? link.active_icon
                  : link.icon,"alt":link.name}})],1),_c('v-list-item-content',{staticClass:"base-body-text"},[_c('v-list-item-title',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(_vm.extract_translation_from_key(link.name))+" ")])],1)],1):(link.module.includes(_vm.module) && link.children)?_c('v-list-group',{staticClass:"sidebar-sub-link-list-group",class:{
            active: _vm.$route.matched.some(function (record) { return record.path.startsWith(link.link.trim()); }
            )
          },scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"sidebar-sub-link ma-0 activator",attrs:{"color":"primary","link":"","to":link.link,"active-class":""}},[_c('v-list-item-icon',{staticClass:"mr-4 ms-2 sidebar-icon"},[_c('inline-svg',{attrs:{"src":_vm.$route.matched.some(function (record) { return record.path.startsWith(link.link.trim()); }
                    )
                      ? link.active_icon
                      : link.icon,"alt":link.name}})],1),_c('v-list-item-content',{staticClass:"base-body-text"},[_c('v-list-item-title',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(_vm.extract_translation_from_key(link.name))+" ")])],1)],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"sub-links-wrapper ms-6 pr-4 pb-3"},_vm._l((link.children),function(sub_link,index){return _c('router-link',{key:index,staticClass:"\n                sidebar-sub-link\n                text-decoration-none\n                d-flex\n                align-center\n              ",class:{
                'mb-3': link.children.length - 1 !== index
              },attrs:{"active-class":"active","color":"primary","link":"","to":sub_link.link}},[_c('div',{staticClass:"sidebar-sub-link-border"}),_c('v-icon',{staticClass:"sidebar-sub-link-dot",attrs:{"x-small":"","color":"grey"}},[_vm._v("mdi-circle")]),_c('p',{staticClass:"mb-0 sidebar-title base-body-text ms-4 mr-2"},[_vm._v(" "+_vm._s(_vm.extract_translation_from_key(sub_link.name))+" ")])],1)}),1)]):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }